
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import gql from "graphql-tag";
import { findLastIndex } from "lodash";
import { DateTime } from "luxon";
import LineGraph from "@/components/visualization/LineGraph.vue";
import SingleComparePropertyDialog from "@/components/SingleComparePropertyDialog.vue";
import AppSpinner from "@/components/AppSpinner.vue";
import UnitSelector from "@/components/UnitSelector.vue";
import { PropertyConfig, Unit, DecoratedAsset, LineGraphSeries, GqlTimeSeries, TimeSeries } from "@/types";
import { assetParamProvider, getPropertyConfig } from "@/config/asset";
import { transformSeriesData } from "@/utils/number";
import energyConfig from "@/energy-pro/config/base-energy-sensor";
import { propertyName } from "@/utils/models";

const DURATION_PERIOD = [
  { days: 0, period: 60 },
  { days: 1, period: 60 * 15 },
  { days: 3, period: 60 * 30 },
  { days: 7, period: 60 * 60 },
  { days: 14, period: 60 * 60 * 6 }
];
const NAVIGATOR_PERIOD = 60 * 60 * 24;

@Component({
  components: {
    LineGraph,
    AppSpinner,
    UnitSelector,
    SingleComparePropertyDialog
  },
  apollo: {
    seriesData: {
      // prettier-ignore
      query: gql`
        query AverageCurrentTrend(
          $deviceUuids: [ID!]!,
          $startDate: DateTime!,
          $endDate: DateTime!,
          $period: Int!)
          {
            seriesData: averageCurrentTrend(
              deviceUuids: $deviceUuids,
              startDate: $startDate,
              endDate: $endDate,
              period: $period
            )
          }
      `,
      variables() {
        return {
          deviceUuids: this.deviceUuids,
          startDate: this.effectiveStartDate.setZone("utc"),
          endDate: this.effectiveEndDate.setZone("utc"),
          period: this.period
        };
      }
    },
    seriesData2: {
      // prettier-ignore
      query: gql`
        query AverageCurrentTrend(
          $deviceUuids: [ID!]!,
          $startDate: DateTime!,
          $endDate: DateTime!,
          $period: Int!)
          {
            seriesData2: averageCurrentTrend(
              deviceUuids: $deviceUuids,
              startDate: $startDate,
              endDate: $endDate,
              period: $period
            )
          }
      `,
      variables() {
        return {
          deviceUuids: this.comparedDeviceUuids,
          startDate: this.effectiveStartDate.setZone("utc"),
          endDate: this.effectiveEndDate.setZone("utc"),
          period: this.period
        };
      },
      skip() {
        return !this.comparedDeviceUuids.length;
      }
    },
    navigatorSeriesData: {
      // prettier-ignore
      query: gql`
        query NavigatorAverageCurrentTrend(
          $deviceUuids: [ID!]!,
          $startDate: DateTime!,
          $endDate: DateTime!,
          $period: Int!)
          {
            navigatorSeriesData: averageCurrentTrend(
              deviceUuids: $deviceUuids,
              startDate: $startDate,
              endDate: $endDate,
              period: $period
            )
          }
      `,
      variables() {
        return {
          deviceUuids: this.deviceUuids,
          startDate: this.navigatorStartDate.setZone("utc"),
          endDate: this.navigatorEndDate.setZone("utc"),
          period: NAVIGATOR_PERIOD
        };
      }
    }
  }
})
export default class PowerGraph extends Vue {
  @Prop({ required: true })
  readonly device: DecoratedAsset;

  @Prop({ type: DateTime })
  readonly startDate?: DateTime;

  @Prop({ type: DateTime })
  readonly endDate?: DateTime;

  @Prop({ type: String, required: true })
  readonly title: string;

  seriesData: GqlTimeSeries | null = null;
  seriesData2: GqlTimeSeries | null = null;
  navigatorSeriesData: GqlTimeSeries | null = null;
  effectiveStartDate: DateTime;
  effectiveEndDate: DateTime;
  navigatorStartDate: DateTime;
  navigatorEndDate: DateTime;
  unit: Unit = "kw";
  showCompareDialog = false;
  comparedAsset: DecoratedAsset | null = null;

  data(): Record<string, any> {
    const navigatorEndDate = DateTime.now().setZone(this.$store.getters.timeZone);
    const navigatorStartDate = navigatorEndDate.minus({ years: 1 }).startOf("day");

    return {
      effectiveStartDate: this.startDate ?? navigatorEndDate.minus({ weeks: 1 }),
      effectiveEndDate: DateTime.min(this.endDate ?? navigatorEndDate, navigatorEndDate),
      navigatorStartDate: navigatorStartDate,
      navigatorEndDate: navigatorEndDate
    };
  }

  @Watch("startDate")
  @Watch("endDate")
  updateEffectiveDates(): void {
    this.effectiveStartDate = this.startDate ?? this.navigatorEndDate.minus({ weeks: 1 });
    this.effectiveEndDate = DateTime.min(this.endDate ?? this.navigatorEndDate, this.navigatorEndDate);
  }

  get period(): number {
    const days = this.effectiveEndDate.diff(this.effectiveStartDate, "days").days;
    const index = findLastIndex(DURATION_PERIOD, g => days >= g.days);
    return DURATION_PERIOD[index].period;
  }

  get series(): LineGraphSeries {
    return this.buildSeries(this.device, this.seriesData, this.$apollo.queries.seriesData.loading);
  }

  get series2(): LineGraphSeries | undefined {
    if (!this.comparedAsset) return undefined;
    return this.buildSeries(this.comparedAsset, this.seriesData2, this.$apollo.queries.seriesData2.loading);
  }

  get allSeries(): LineGraphSeries[] {
    const all = [this.series];
    if (this.series2) all.push(this.series2);
    return all;
  }

  get navigatorSeries(): LineGraphSeries {
    return this.buildSeries(this.device, this.navigatorSeriesData, this.$apollo.queries.navigatorSeriesData.loading);
  }

  buildSeries(asset: DecoratedAsset, seriesData: GqlTimeSeries | null, loading: boolean): LineGraphSeries {
    const { convertValueFn: convertFn } = this.propertyConfig;
    let series: TimeSeries = [];

    if (seriesData !== null) {
      const paramProvider = assetParamProvider(asset);
      series = transformSeriesData(seriesData, "average_energy", convertFn, paramProvider);
    }

    return {
      loading: loading || seriesData === null,
      series,
      entityName: asset.name,
      propertyName: propertyName(asset, "average_energy"),
      buildingName: asset.building?.name ?? ""
    };
  }

  get deviceUuids(): string[] {
    return [this.device.assetUuid];
  }

  get comparedDeviceUuids(): string[] {
    return this.comparedAsset ? [this.comparedAsset.assetUuid] : [];
  }

  get propertyConfig(): PropertyConfig {
    return getPropertyConfig(energyConfig, "average_energy", this.unit);
  }

  openCompareDialog(): void {
    this.showCompareDialog = true;
  }
}
