
import { Vue, Component, Prop } from "vue-property-decorator";
import gql from "graphql-tag";
import { DateTime } from "luxon";
import DailyColumnChart from "@/components/visualization/DailyColumnChart.vue";
import AppSpinner from "@/components/AppSpinner.vue";
import { DecoratedAsset, EnergyConsumption, ActualEstimatedSeriesDatum } from "@/types";

@Component({
  components: {
    DailyColumnChart,
    AppSpinner
  },
  apollo: {
    energyConsumptionByTime: {
      query: gql`
        query EnergyQuery($deviceUuid: ID!, $startDate: DateTime!, $endDate: DateTime!) {
          energyConsumptionByTime(deviceUuid: $deviceUuid, startDate: $startDate, endDate: $endDate) {
            points {
              time
              kwhEst
              kwhActual
            }
          }
        }
      `,
      variables() {
        const startDate = this.startDate.startOf("day").setZone("utc");
        const endDate = this.endDate.endOf("day").setZone("utc");

        return {
          deviceUuid: this.asset.assetUuid,
          startDate,
          endDate
        };
      },
      fetchPolicy: "no-cache",
      notifyOnNetworkStatusChange: true
    }
  }
})
export default class AssetEnergyChart extends Vue {
  @Prop({ type: Object, required: true })
  readonly asset: DecoratedAsset;

  @Prop({ type: DateTime, required: true })
  readonly startDate: DateTime;

  @Prop({ type: DateTime, required: true })
  readonly endDate: DateTime;

  energyConsumptionByTime: EnergyConsumption | null = null;

  get dataAvailable(): boolean {
    return this.energyConsumptionByTime !== null;
  }

  get buildingName(): string {
    return this.asset?.building?.name ?? "";
  }

  get assetName(): string {
    return this.asset?.name ?? "";
  }

  get series(): ActualEstimatedSeriesDatum[] | null {
    const points = this.energyConsumptionByTime?.points;
    if (points === undefined) return null;

    return points.map(point => {
      return {
        time: DateTime.fromISO(point.time).setZone(this.$store.getters.timeZone),
        actual: point.kwhActual,
        estimated: point.kwhEst
      };
    });
  }
}
